import VueRouter from 'vue-router';
import Vue from 'vue';



const notFound = r => require.ensure([], () => r(require('@/backend/exception/NotFound')), 'not-found');

const website = r => require.ensure([], () => r(require('@/Website')), 'website');

const backend = r => require.ensure([], () => r(require('@/backend/Backend')), 'backend-index');

const login = r => require.ensure([], () => r(require('@/backend/Login')), 'login');

const userList = r => require.ensure([], () => r(require('@/backend/user/UserList')), 'user-list');
const activeList = r => require.ensure([], () => r(require('@/backend/user/ActiveList')), 'active-list');

const activecodeGen = r => require.ensure([], () => r(require('@/backend/activecode/ActivecodeGen')), 'activecode-gen');
const activecodeSearch = r => require.ensure([], () => r(require('@/backend/activecode/ActivecodeSearch')), 'activecode-search');

// const userAtiveRecord = r => require.ensure([], () => r(require('@/backend/user/UserActiveRecord')), 'active-record');

const orderAdd = r => require.ensure([], () => r(require('@/backend/order/OrderAdd')), 'order-add');
const orderList = r => require.ensure([], () => r(require('@/backend/order/OrderList')), 'order-list');
const merchantManager = r => require.ensure([], () => r(require('@/backend/order/MerchantManager')), 'merchant-manager');

const branchOrderList = r => require.ensure([], () => r(require('@/backend/user/BranchOrderList')), 'branch-order-list');

// const changePwd = r => require.ensure([], () => r(require('@/backend/user/ChangePwd')), 'change-pwd');


const constantRouterMap = [
  {
    path: '/login/',
    name: 'login',
    meta: { title: '登录' },
    component: login
  },
  {
    path: '/404/',
    name: 'not-found',
    meta: {title: '404'},
    component: notFound
  }
]

export const leftMenuItems = [
  {
    menu: 'user-manager',
    name:'用户管理',
    items:[
      {
        path: 'user-list/',
        name: 'user-list',
        component: userList,
        meta:{
          type: [0],
          label: "用户"
        }
      },
      {
        path: 'active-list/',
        name: 'active-list',
        component: activeList,
        meta:{
          type: [0,1],
          label: "激活"
        }
      },
      {
        path: 'branch-order-list/',
        name: 'branch-order-list',
        component: branchOrderList,
        meta:{
          type: [0],
          label:"订单列表"
        }
      },
    ]
  },
  {
    menu: 'activecode-manager',
    name:'激活码管理',
    items:[
      {
        path:'activecode-gen/',
        name: 'activecode-gen',
        component: activecodeGen,
        meta:{
          type: [0],
          label:'生成激活码'
        }
      },
      {
        path:'activecode-search/',
        name: 'activecode-search',
        component: activecodeSearch,
        meta:{
          type: [0],
          label:'激活码查询'
        }
      },
    ]
  },
  {
    menu: 'order-manager',
    name:'订单管理',
    items:[
      {
        path:'order-add/',
        name: 'order-add',
        component: orderAdd,
        meta:{
          type: [1],
          label:'订单添加'
        }
      },
      {
        path:'order-list/',
        name: 'order-list',
        component: orderList,
        meta:{
          type: [1],
          label:'订单列表'
        }
      },
      {
        path:'merchant-manager/',
        name: 'merchant-manager',
        component: merchantManager,
        meta:{
          type: [0],
          label:'商家订单管理'
        }
      },
    ]
  },
  // {
  //   menu: 'settings',
  //   name:'设置',
  //   items:[
  //     {
  //       path: 'change-pwd/',
  //       name: 'change-pwd',
  //       component: changePwd,
  //       meta:{
  //         type: [0,1],
  //         label:'修改密码'
  //       }
  //     },
  //   ]
  // }
]

var menuRoutes = [].concat.apply([], leftMenuItems.map(item=>{
  return item.items
}));

const routes = [
  {
    path: '/',
    redirect: { name: 'home'}
  },
  {
    path: '/home/',
    name: 'home',
    component: website
  },
  {
    path: '/backend/',
    name: 'backend',
    component: backend,
    children: [
      {
        path:'',
        redirect: {name: 'user-list'}
      },
    ].concat(menuRoutes)
  },
  {
    path: '*',
    redirect: {name:'not-found'}
  }
];


Vue.use(VueRouter)

const RouterConfig = {
  mode: 'hash',
  routes: constantRouterMap.concat(routes)
}

const router = new VueRouter(RouterConfig);

export default router;

