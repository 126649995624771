import Vue from 'vue';

const LOGIN_KEY = "user_login_state";
const USER_KEY = "user_info";

const Store = {
  debug: true,
  state:{
    activeName: null,
    deviceType: null,
    isLogin: -1,
    user: null,
  },
  setActiveName(newValue){
    // if(this.debug) console.log("setActiveName: ",newValue);
    this.state.activeName = newValue;
  },
  clearUserLogin(){
    this.state.isLogin = false;
    Vue.ls.set(LOGIN_KEY,false);
    Vue.ls.remove(USER_KEY);
  },
  setUserLogin(user){
    this.state.isLogin = true
    this.state.user = user;
    Vue.ls.set(LOGIN_KEY,true);
    Vue.ls.set(USER_KEY,JSON.stringify(user));
  },
  init(){
    this.state.isLogin = Vue.ls.get(LOGIN_KEY,false);
    var user = Vue.ls.get(USER_KEY);
    if(user){
      this.state.user = JSON.parse(user);
    }
  }
};

export default Store;